import styled from "styled-components";

const Wrapper = styled.div`
  padding: 8px 0;
`
const Title = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 700;
`
const SpecsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`
const SpecColumn = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.greyTextColor2};
  line-height: 20px;
  font-weight: 400;
`

function DetailSpecs({ modelSpec }) {

  return (
    <Wrapper>
      <Title>기기 상세 스펙</Title>
      <SpecsWrapper>
        {modelSpec && Object.values(modelSpec).map(ele => (
          <SpecColumn key={ele[0]}>
            <span>{ele[0]}</span>
            <span>{ele[1]}</span>
          </SpecColumn>
        ))}
      </SpecsWrapper>
    </Wrapper>
  )
}

export default DetailSpecs