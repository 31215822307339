export function getAmountNumberFormat(amount) {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function hasMaxMin(arr, attrib, isMax) {
  return (arr?.length && arr.reduce(function(prev, curr){
    if(isMax) {
      return prev[attrib] > curr[attrib] ? prev : curr;
    }
    return prev[attrib] < curr[attrib] ? prev : curr;
  })) || null;
}