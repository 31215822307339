import styled from "styled-components";

const MenuItem = styled.button`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.checked ? props.theme.blackColor : 'white'};
  color: ${props => props.checked ? 'white' : 'black'};
  border: ${props => !props.disable ? props.checked ? '1px solid' + props.theme.blackColor : '1px solid #EEEDF0' : ''};
  border-radius: 8px;
  line-height: 24px;
  transition: all 0.1s linear;
`
const MenuItemTitle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`
const MenuItemSubTitle = styled.div`
  margin-top: 4px;
  font-weight: 400;
  font-size: 11px;
  color: ${props => props.checked ? 'white' : props.theme.greyTextColor};
`

function SelectProductOptionButton({ value, title, subTitle, disable, selectedValue, setSelectedValue, index }) {

  function onClick(event) {
    setSelectedValue(event.currentTarget.value)
  }

  return (
    <MenuItem id={`item${index}`} value={value} onClick={(onClick && value) && onClick}
              checked={selectedValue === value} disable={disable}>
      <MenuItemTitle>{title}</MenuItemTitle>
      {subTitle && <MenuItemSubTitle checked={selectedValue === value}>{subTitle}</MenuItemSubTitle>}
    </MenuItem>
  )
}

export default SelectProductOptionButton