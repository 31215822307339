import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import GlobalContext from "../../../contexts/store";
import TopBar from "../../../domain/v2/lease/common/TopBar";
import Divider from "../../../domain/v2/lease/common/Divider";
import { nativeInvoke } from "../../../utils/bridge";
import { allSteps } from "../../../domain/v2/lease/common/hook";
import Loading from "../../../components/common/Loading";

import { getDevicesAPI } from "../../../domain/v2/lease/device/device.service";
import SelectDeviceCarousel from "../../../domain/v2/lease/device/SelectDeviceCarousel";
import DeviceTitle from "../../../domain/v2/lease/device/DeviceTitle";
import DetailSpecs from "../../../domain/v2/lease/device/DetailSpecs";
import Announcement from "../../../domain/v2/lease/device/Announcement";
import DeviceFixedBottomButton from "../../../domain/v2/lease/device/DeviceFixedBottomButton";
import DepositRefundInfo from "../../../domain/v2/lease/common/DepositRefundInfo";
import PreviewDeviceCarousel from "../../../domain/v2/lease/device/PreviewDeviceCarousel";
import LargeHeader from "../../../domain/v2/lease/device/LargeHeader";

function SelectDevicePage() {
  const { state: { selectedDevice } } = useContext(GlobalContext)
  const {
    data: { devices },
    isLoading,
  } = useDeviceQuery()

  const {
    modelName, leasePrice, modelSpec, modelType, steps, deposit, imageUrls
  } = selectedDevice ?? devices[0]


  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar title={selectedDevice?.modelName}
              rightItem={{}}
              backClick={() => nativeInvoke('goToMain')}
              scroll={scroll}/>
      <LargeHeader/>

      <SelectDeviceCarousel devices={devices}/>

      <div style={{ padding: '8px 0' }}>
        <DeviceTitle title={modelName} leasePrice={leasePrice}/>
        <DepositRefundInfo deposit={deposit}/>
      </div>

      <Divider/>
      <DetailSpecs modelSpec={modelSpec}/>
      <Divider/>

      <PreviewDeviceCarousel modelName={modelName} imageUrls={imageUrls}/>
      <Divider/>
      <Announcement/>

      <DeviceFixedBottomButton modelType={modelType} steps={steps}/>
    </>
  )
}

export default SelectDevicePage

function useDeviceQuery() {

  const {
    data: deviceData,
    isLoading
  } = useQuery(['device'], getDevicesAPI, {
    suspense: true
  })

  const devices = deviceData?.map(device => ({
    ...device,
    steps: allSteps.find(ele => ele.modelType === device.modelType).steps
  }))

  return {
    data: {
      devices,
    },
    isLoading,
  }
}