import { API } from "../../../../utils/api";

export async function getDeviceGradeAPI(modelType) {
  return (await API.get(`v2/lease/grades?modelType=${modelType}`)).data
}

export async function postCalculatePriceAPI(
  { modelType, grade, periodType, insuranceType, productId }
) {
  return (await API.post(`v2/lease/order/calc`, { modelType, grade, periodType, insuranceType, productId })).data
}