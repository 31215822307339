import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import GlobalContext from "../../contexts/store";
import { useStepControl } from "../../domain/v1/common/hook";
import Loading from "../../components/common/Loading";
import TopBar from "../../domain/v1/common/TopBar";
import TabBar from "../../domain/v1/common/TabBar";
import Progress from "../../domain/v1/common/Progress";
import DisplayProductPicture from "../../domain/v1/common/DisplayProductPicture";
import SelectProductOptionMenu from "../../domain/v1/common/SelectProductOptionMenu";
import SelectProductOptionButton from "../../domain/v1/common/SelectProductOptionButton";
import InfoMessage from "../../domain/v1/period/InfoMessage";
import FixedCalculatePriceBottomButton from "../../domain/v1/common/FixedCalculatePriceBottomButton";
import { getInsuranceAPI } from "../../domain/v1/insurance/insurance.service";
import { nativeInvoke } from "../../utils/bridge";

function SelectInsurancePage() {
  const { modelType } = useParams()
  const { state: { selectedDevice, selectedOptions: { insuranceType } }, setState } = useContext(GlobalContext)
  const { data: { insurances }, isLoading } = usePeriodQuery(modelType)

  const { prev, now, next, move } = useStepControl('insurance')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={insurances?.imageUrl} style={{ width: '120px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={insurances?.leaseInsurances[0].insuranceType.toString()}
        menuTitle={{
          title: '필수 보험을 선택해주세요', subTitle: '자세히',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://teamalley.notion.site/d6661e77499742bcba472518b13b322c' }
          )
        }}
        onChange={(value) => setState(prev => ({
          ...prev,
          selectedOptions: { ...prev.selectedOptions, insuranceType: value }
        }))}
        column={insurances?.leaseInsurances.length === 1 ? 1 : 2}
      >
        {insurances?.leaseInsurances?.map(ele => (
          <SelectProductOptionButton
            key={ele.insuranceType}
            value={ele.insuranceType}
            title={ele.displayInsurance}
            subTitle={ele.displayDescription}
          />))}
      </SelectProductOptionMenu>

      <InfoMessage text={
        selectedDevice?.modelType === 'S9'
          ? '책임보험은 사고 발생 시 대인 최대 4천만원, 대물 최대 1천만원까지 보장돼요. 단 본인부담금 30만원이 발생할 수 있어요.'
          : insuranceType === 'BASIC'
            ? '책임보험은 대인 사고 시 사망 1.5억원, 부상 3천만원 한도로 보장되고, 대물 사고 시 2천만원 한도로 보상돼요.'
            : insuranceType === 'PREMIUM'
              ? '스윙 케어는 대인 사고 시 사망 및 부상의 경우 최대 3억원 한도, 대물 사고 시 2천만원 한도로 보상 돼요.'
              : ''
      }/>

      <FixedCalculatePriceBottomButton optionType={'insuranceType'} now={now} next={next} move={move}/>
    </>
  )
}

export default SelectInsurancePage

function usePeriodQuery(modelType) {

  const {
    data: insurances, isLoading
  } = useQuery(['insurance', modelType], () => getInsuranceAPI(modelType), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      insurances
    },
    isLoading
  }
}