import React, { useContext } from "react";
import styled from "styled-components";

import { useCalculatePrice, useStepControl } from "../../domain/v1/common/hook";
import TopBar from "../../domain/v1/common/TopBar";
import SelectionDescriptions from "../../domain/v1/order/SelectionDescriptions";
import FixedLeaseOrderBottomButton from "../../domain/v1/order/FixedLeaseOrderBottomButton";
import GlobalContext from "../../contexts/store";

const Title = styled.div`
  padding: 16px 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
`

function OrderPage() {
  const { prev, move } = useStepControl('order')
  const { data: { calcPrices } } = useCalculatePrice();

  const { agrees, handler } = useAgree()

  return (
    <>
      <TopBar
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <Title>신청하실 리스 정보를<br/> 확인해주세요</Title>

      <SelectionDescriptions calcPrices={calcPrices}/>

      {/*todo 이용약관 동의 임시 제거*/}
      {/*<Agreement handler={handler} agrees={agrees}/>*/}

      <FixedLeaseOrderBottomButton isAllCheckedRequired={handler.isAllCheckedRequired}/>
    </>
  )
}

export default OrderPage

function useAgree() {
  const { state: { agrees }, setState } = useContext(GlobalContext)

  function isAllCheckedRequired() {
    //todo 이용약관 동의 임시 제거
    return true
    // return !Object.values(agrees)
    //   .map(agreeValue => JSON.stringify(agreeValue))
    //   .includes(JSON.stringify({ checked: false, required: true }))
  }

  function handleCheck(checkType) {
    Object.keys(agrees).forEach(key => (key === checkType) && (
      setState(prev => ({
        ...prev,
        agrees: {
          ...prev.agrees,
          [key]: { ...prev.agrees[key], checked: !prev.agrees[key].checked }
        },
      }))
    ))
  }

  function handleAllCheck() {
    if (Object.values(agrees).map(ele => ele.checked).includes(false)) {
      setState(prev => ({
        ...prev,
        agrees: {
          ...prev.agrees,
          termsAndConditions: { ...prev.agrees.termsAndConditions, checked: true },
          collectPrivateInfo: { ...prev.agrees.collectPrivateInfo, checked: true },
          providePrivateInfo: { ...prev.agrees.providePrivateInfo, checked: true }
        },
      }))
    } else {
      setState(prev => ({
        ...prev,
        agrees: {
          ...prev.agrees,
          termsAndConditions: { ...prev.agrees.termsAndConditions, checked: false },
          collectPrivateInfo: { ...prev.agrees.collectPrivateInfo, checked: false },
          providePrivateInfo: { ...prev.agrees.providePrivateInfo, checked: false }
        },
      }))
    }
  }

  return {
    agrees,
    handler: {
      isAllCheckedRequired,
      handleAllCheck,
      handleCheck
    }
  }
}