import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import GlobalContext from "../../../contexts/store";
import { nativeInvoke } from "../../../utils/bridge";
import { getAccessToken } from "../../../utils/api";
import FixedBottomButtonWrapper from "../common/FixedBottomButtonWrapper";
import { ArrowLongLeftIcon } from "../../../images";

const LeftButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #F8F7FA;
  border: none;
  margin-right: 12px;
`
const RightButton = styled.button`
  padding: 18px 20px;
  background-color: ${props => props.theme.blackColor};
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`

function DeviceFixedBottomButton({ modelType, steps }) {
  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const navigate = useNavigate()

  function handleNextStep() {
    if (!getAccessToken()) {
      return nativeInvoke('goToLogin')
    }

    setState(prev => ({ ...prev, selecting: true }))
    navigate(`${selectedDevice.modelType}/${selectedDevice.steps[1]}`)
    navigate(`${modelType}/${steps[1]}`)
  }

  return (
    <FixedBottomButtonWrapper>
      <LeftButton onClick={() => nativeInvoke('back')}>
        <img src={ArrowLongLeftIcon}/>
      </LeftButton>

      <RightButton onClick={handleNextStep}>다음</RightButton>
    </FixedBottomButtonWrapper>
  )
}

export default DeviceFixedBottomButton