import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import GlobalContext from "../../../contexts/store";

import { useStepControl } from "../../../domain/v2/lease/common/hook";
import TopBar from "../../../domain/v2/lease/common/TopBar";
import TabBar from "../../../domain/v2/lease/common/TabBar";
import Progress from "../../../domain/v2/lease/common/Progress";
import DisplayProductPicture from "../../../domain/v2/lease/common/DisplayProductPicture";
import SelectProductOptionButton from "../../../domain/v2/lease/common/SelectProductOptionButton";
import SelectProductOptionMenu from "../../../domain/v2/lease/common/SelectProductOptionMenu";
import FixedCalculatePriceBottomButton from "../../../domain/v2/lease/common/FixedCalculatePriceBottomButton";

import { getPeriodAPI } from "../../../domain/v2/lease/period/period.service";
import Loading from "../../../components/common/Loading";
import { hasMaxMin } from "../../../utils/organizeData";
import { nativeInvoke } from "../../../utils/bridge";

function SelectPeriodPage() {
  const { modelType } = useParams()

  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { periods }, isLoading } = usePeriodQuery(modelType)
  const { prev, now, next, move } = useStepControl('period')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={periods?.imageUrl} style={{ width: '120px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={hasMaxMin(periods?.leasePeriods, 'period', true)?.period.toString()}
        menuTitle={{
          title: '리스 기간 선택',
          // subTitle: '인수 안내',
          subTitle: '',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://teamalley.notion.site/a4fbb8e0b2084ba58296a4a490c91c94' }
          )
        }}
        onChange={(value) => {
          const target = periods?.leasePeriods.find(ele => String(ele.period) === value)
          setState(prev => ({
            ...prev,
            selectedOptions: { ...prev.selectedOptions, periodType: Number(value) },
            selectedOptionsLabel: { ...prev.selectedOptionsLabel, periodName: target?.displayPeriod }
          }))
        }}
        column={periods?.leasePeriods.length === 1 ? 1 : 2}
      >
        {periods?.leasePeriods
          ?.sort((a, b) => a.period - b.period)
          ?.map(ele => (
            <SelectProductOptionButton
              key={ele.period}
              value={ele.period.toString()}
              title={ele.displayPeriod}
              subTitle={ele.subTitle}
            />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'periodType'} now={now} prev={prev} next={next} move={move}/>
    </>
  )
}

export default SelectPeriodPage

function usePeriodQuery(modelType) {

  const {
    data: periods, isLoading
  } = useQuery(['period', modelType], () => getPeriodAPI(modelType), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      periods
    },
    isLoading
  }
}