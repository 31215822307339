import React from "react";
import styled from "styled-components";
import CountUp from 'react-countup';

import { useCalculatePrice } from "./hook";
import FixedBottomButtonWrapper from "./FixedBottomButtonWrapper";

const LeftButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none;
  margin-right: 12px;
  flex: 1;
  padding: 0 4px;
  background-color: white;
  font-family: SUIT, Helvetica, serif;
`
const RightButton = styled.button`
  padding: 18px 20px;
  background-color: ${props => props.theme.blackColor};
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`
const TotalPriceColumn = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 2px;
  color: black;
  span {
    font-size: 22px;
    font-weight: 700;
    margin-left: 8px;
  }
`
const SubPriceColumn = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.greyColor1};
`

function FixedCalculatePriceBottomButton({ now, next, move, optionType }) {
  const { data: { calcPrices } } = useCalculatePrice(optionType);

  return (
    <FixedBottomButtonWrapper>
      <LeftButton>
        <TotalPriceColumn>월 <span>
          <CountUp start={50000} end={calcPrices?.totalPrice ?? 100000} separator={','} duration={0.7}
                   style={{ margin: 0 }}/>
        </span>원</TotalPriceColumn>
        <SubPriceColumn>
          {now.value === 'option'
            ? calcPrices?.displayPeriod
            : calcPrices?.displayInsuranceFee ? `(${calcPrices?.displayInsuranceFee})` : ''}
        </SubPriceColumn>
      </LeftButton>

      <RightButton onClick={() => move(next.value)}>
        {next?.value === 'order' ? '선택 완료' : '다음'}
      </RightButton>
    </FixedBottomButtonWrapper>
  )
}

export default FixedCalculatePriceBottomButton