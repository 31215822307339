import React from "react";
import styled from "styled-components";
import { ArrowBackIcon } from "../../../../images";

const TopBarWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  z-index: 100;

  div:first-child {
    flex: 1;
    display: flex;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`
const BackButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  opacity: ${props => props.scroll ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
`
const RightItem = styled.div`
  font-size: 16px;
  color: ${props => props.theme.greyTextColor};
  font-weight: 500;
`

function TopBar({ title, rightItem: { text = '', onClick }, backClick, scroll = true }) {

  return (
    <TopBarWrapper>
      <div><BackButton src={ArrowBackIcon} onClick={backClick}/></div>
      <div><Title scroll={scroll}>{title}</Title></div>
      <div><RightItem onClick={onClick}>{text}</RightItem></div>
    </TopBarWrapper>
  )
}

export default TopBar