import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`
const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
`
const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.greyTextColor2};
`

function LargeHeader(){

  return (
    <Wrapper>
      <Title>어떤 기기를 리스하시겠어요?</Title>
      <Subtitle>직접 픽업하거나 집 앞까지 배송 받을 수 있어요.</Subtitle>
    </Wrapper>
  )
}

export default LargeHeader