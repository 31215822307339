import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0 20px;
`
const InfoBox = styled.div`
  display: flex;
  
  padding: 16px;
  background-color: ${props => props.theme.dividerColor};
  border-radius: 8px;
`
const Tip = styled.div`
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.blackColor};
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  margin-right: 20px;
`
const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #303030;
`

function InfoMessage({text = ''}) {

  return (
    <Wrapper>
      <InfoBox>
        <Tip>TIP!</Tip>
        <Text>{text}</Text>
      </InfoBox>
    </Wrapper>
  )
}

export default InfoMessage