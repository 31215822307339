import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #EEEDF0;

  * {
    margin: 0 5px;
  }
`

function DisplayProductPicture({ children }) {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default DisplayProductPicture