import { useContext, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import GlobalContext from "../../../../contexts/store";

const ProgressWrapper = styled.div`
  padding: 12px 20px;
`
const ProgressBar = styled.div`
  height: 8px;
  background-color: #EEEDF0;
  border-radius: 4px;
`
const ProgressStep = styled.div`
  width: ${props => (props.step)}%;
  height: 100%;
  background-color: #FF9900;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
`

function Progress() {
  const { pathname } = useLocation()
  const { state: { prevProgress }, setState } = useContext(GlobalContext)

  useEffect(() => {
    if (pathname === '/v2/lease') return setState(prev => ({ ...prev, prevProgress: 10 }))
    if (pathname.includes('grade')) return setState(prev => ({ ...prev, prevProgress: 20 }))
    if (pathname.includes('period')) return setState(prev => ({ ...prev, prevProgress: 40 }))
    if (pathname.includes('insurance')) return setState(prev => ({ ...prev, prevProgress: 60 }))
    if (pathname.includes('extraOption')) return setState(prev => ({ ...prev, prevProgress: 80 }))
  }, [pathname])

  return (
    <ProgressWrapper>
      <ProgressBar>
        <ProgressStep step={prevProgress}/>
      </ProgressBar>
    </ProgressWrapper>
  )
}

export default Progress