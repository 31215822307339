import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import GlobalContext from "../../contexts/store";
import { useStepControl } from "../../domain/v1/common/hook";
import Loading from "../../components/common/Loading";
import TopBar from "../../domain/v1/common/TopBar";
import TabBar from "../../domain/v1/common/TabBar";
import Progress from "../../domain/v1/common/Progress";
import DisplayProductPicture from "../../domain/v1/common/DisplayProductPicture";
import SelectProductOptionMenu from "../../domain/v1/common/SelectProductOptionMenu";
import SelectProductOptionButton from "../../domain/v1/common/SelectProductOptionButton";
import FixedCalculatePriceBottomButton from "../../domain/v1/common/FixedCalculatePriceBottomButton";
import { getDeviceGradeAPI } from "../../domain/v1/grade/grade.service";
import { nativeInvoke } from "../../utils/bridge";

function SelectGradePage() {
  const { modelType } = useParams()

  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { deviceGrade }, isLoading } = useGradeQuery(modelType);
  const { prev, now, next, move } = useStepControl('grade')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={deviceGrade?.imageUrl} style={{ width: '180px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={deviceGrade?.pcxGrades[0]?.grade}
        menuTitle={{
          title: '등급을 선택해주세요', subTitle: '등급 기준',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://www.notion.so/teamalley/f99e793a61d14a41afa1db2fd1afd93f' }
          )
        }}
        // isPlaceholder={true}
        onChange={(value) =>
          setState(prev => ({
            ...prev,
            selectedOptions: { ...prev.selectedOptions, grade: value }
          }))
        }
        column={deviceGrade?.pcxGrades.length === 1 ? 1 : 2}
      >
        {deviceGrade?.pcxGrades?.map(ele => (
          <SelectProductOptionButton
            key={ele.grade}
            value={ele.grade}
            title={ele.displayGrade}
            subTitle={ele.displayPrice}
          />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'grade'} now={now} next={next} move={move}/>
    </>
  )
}

export default SelectGradePage

function useGradeQuery(modelType) {

  const {
    data: deviceGrade, isLoading
  } = useQuery(['grade', modelType], () => getDeviceGradeAPI(modelType.toLowerCase()), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      deviceGrade,
    },
    isLoading
  }
}