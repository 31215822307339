import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import GlobalContext from "../../contexts/store";
import { useStepControl } from "../../domain/v1/common/hook";
import Loading from "../../components/common/Loading";
import TopBar from "../../domain/v1/common/TopBar";
import TabBar from "../../domain/v1/common/TabBar";
import Progress from "../../domain/v1/common/Progress";
import DisplayProductPicture from "../../domain/v1/common/DisplayProductPicture";
import SelectProductOptionMenu from "../../domain/v1/common/SelectProductOptionMenu";
import SelectExtraOptionButton from "../../domain/v1/option/SelectExtraOptionButton";
import FixedCalculatePriceBottomButton from "../../domain/v1/common/FixedCalculatePriceBottomButton";
import { getExtraOptionAPI } from "../../domain/v1/option/extraOption.service";

function SelectOptionPage() {
  const { modelType } = useParams()
  const { state: { selectedDevice, selectedOptions: { periodType } }, setState } = useContext(GlobalContext)
  const { data: { extraOptions }, isLoading } = useExtraOptionQuery(modelType, periodType)

  const { prev, now, next, move } = useStepControl('option')

  function handleExtraOptionChange(selectedOptions) {

    setState(prev => ({
      ...prev,
      selectedOptions: { ...prev.selectedOptions, extraProductType: selectedOptions }
    }))
  }

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={"https://cdn.swingmobility.dev/swing_asset/47b8f5b9-bed8-45c4-b764-6878f6eb149b"}
             style={{ width: '120px' }}/>
        <img src={"https://cdn.swingmobility.dev/swing_asset/6588de04-53ce-49d2-af7f-9977e2a01ef9"}
             style={{ width: '120px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={[]}
        column={1}
        menuTitle={{ title: '추가 옵션을 선택해주세요' }}
        onChange={handleExtraOptionChange}
      >
        {extraOptions?.leaseOptions?.map(ele => (
          <SelectExtraOptionButton
            key={ele.extraProductType}
            value={ele.extraProductType}
            title={ele.productName}
            subTitle={ele.description}
            price={ele.displayPrice}
          />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'extraProductType'} now={now} next={next} move={move}/>
    </>
  )
}

export default SelectOptionPage

function useExtraOptionQuery(modelType, periodType) {

  const {
    data: extraOptions, isLoading
  } = useQuery(['extraOption', modelType], () => getExtraOptionAPI({ modelType, periodType }), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      extraOptions
    },
    isLoading
  }
}