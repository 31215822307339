import styled from "styled-components";
import { CheckIcon } from "../../../images";

const MenuItem = styled.button`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${props => props.checked ? '1px solid' + props.theme.blackColor : '1px solid #EEEDF0'};
  border-radius: 8px;
  line-height: 24px;
  transition: all 0.1s linear;
`

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const MenuItemTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${props => props.theme.blackColor};
`
const MenuItemSubTitle = styled.div`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  color: ${props => props.theme.greyTextColor};
`
const MenuItemPrice = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${props => props.theme.blackColor};
`

const CheckColumn = styled.img`
  opacity: ${props => props.checked ? 1 : 0.2};
`

function SelectExtraOptionButton({ value, title, subTitle, price, disable, selectedValue, setSelectedValue, index }) {

  function onClick(event) {
    const currentValue = event.currentTarget.value
    setSelectedValue(prev => {
      if (prev.includes(currentValue)) return [...prev.filter(prevValue => prevValue !== currentValue)]
      return [...prev, currentValue]
    })
  }

  return (
    <MenuItem id={`item${index}`} value={value} onClick={(onClick && value) && onClick}
              checked={selectedValue.includes(value)} disable={disable}>
      <TitleColumn>
        <MenuItemTitle>{title}</MenuItemTitle>
        {subTitle && <MenuItemSubTitle>{subTitle}</MenuItemSubTitle>}
        {price && <MenuItemPrice>{price}</MenuItemPrice>}
      </TitleColumn>
      <CheckColumn checked={selectedValue.includes(value)} src={CheckIcon}/>
    </MenuItem>
  )
}

export default SelectExtraOptionButton