import { useState } from "react";
import styled from "styled-components";
import { ArrowDownIcon, ArrowUpIcon } from "../../../images";

const Wrapper = styled.div`
  margin-bottom: 12px;
`
const CollapseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px;
  height: 54px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
`
const CollapseDescriptionWrapper = styled.div`
  color: ${props => props.theme.greyColor1};
`
const CollapseDescriptionItem = styled.div`
  padding: 12px 30px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  div:first-child {
    margin-right: 5px;
  }
`

function Collapse({ title, descriptions = [] }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Wrapper>
      <CollapseWrapper onClick={() => setIsExpanded(prev => !prev)}>
        <div>{title}</div>
        <img src={isExpanded ? ArrowUpIcon : ArrowDownIcon}/>
      </CollapseWrapper>

      {isExpanded && (
        <CollapseDescriptionWrapper>
          {descriptions.map((item, idx) => (
            <CollapseDescriptionItem key={idx}>
              <div>&#8226;</div>
              <div>{item}</div>
            </CollapseDescriptionItem>
          ))}
        </CollapseDescriptionWrapper>)}
    </Wrapper>
  )
}

export default Collapse