import React, { useState } from 'react'
import { useLocation } from "react-router-dom";

/** V2 버전용 */
export const leaseV2initialSelectedOptions = {
  modelType: null,
  grade: null,
  periodType: null,
  insuranceType: null,
  productId: null,
}

export const leaseV2initialSelectedOptionsLabel = {
  gradeName: null,
  periodName: null,
  insuranceName: null,
  extraProductName: '추가 안함',
}

const initialOrderPayload = {
  ...leaseV2initialSelectedOptions,
  ...leaseV2initialSelectedOptionsLabel,
  modelName: null,
  depositDisplayText: null,
  deposit: null,
  deliveryPrice: null,
  displayInsuranceFee: null,
  leasePrice: null,
}

const leaseV2initialState = {
  selectedDevice: null,
  prevProgress: 0,
  selecting: false,
  calcPrices: 0,
  selectedOptions: leaseV2initialSelectedOptions,
  selectedOptionsLabel: leaseV2initialSelectedOptionsLabel,
  orderPayload: initialOrderPayload
}


/** V1 버전용 */
export const leaseV1initialSelectedOptions = {
  modelType:null,
  grade: null,
  periodType: null,
  insuranceType:null,
  extraProductType:[],
}

const leaseV1initialState = {
  selectedDevice: null,
  prevProgress: 0,
  selecting: false,
  calcPrices:0,
  agrees:{
    termsAndConditions: { checked: false, required: true },
    collectPrivateInfo: { checked: false, required: true },
    providePrivateInfo: { checked: false, required: true }
  },
  selectedOptions: leaseV1initialSelectedOptions
}

const GlobalContext = React.createContext(null)

export function GlobalContextProvider({ children }) {
  const location = useLocation()

  const routeGlobalContext = () => {
    if(location.pathname.includes('v2/lease')){
      return leaseV2initialState
    } else {
      return leaseV1initialState
    }
  }
  const [state, setState] = useState(routeGlobalContext());

  // console.log('selectedDevice', state.selectedDevice, 'selectedOptions', state.selectedOptions, 'state', state)
  // console.log('payload payload payload', state.orderPayload)
  return (
    <GlobalContext.Provider value={{ state, setState }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext;