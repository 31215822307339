import React from "react";
import styled from "styled-components";
import { ArrowBackIcon } from "../../../images";

const TopBarWrapper = styled.div`
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  div:first-child {
    flex: 1;
    display: flex;
  }
  div:nth-child(1) {
    flex: 1;
  }
  div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`
const BackButton = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`
const RightItem = styled.div`
  font-size: 16px;
  color: ${props => props.theme.greyTextColor};
  font-weight: 500;
`

function TopBar({ title, rightItem: { text = '', onClick }, backClick }) {

  return (
    <TopBarWrapper>
      <div><BackButton src={ArrowBackIcon} onClick={backClick}/></div>
      <div><Title>{title}</Title></div>
      <div><RightItem onClick={onClick}>{text}</RightItem></div>
    </TopBarWrapper>
  )
}

export default TopBar