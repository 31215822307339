import Collapse from "../common/Collapse";

function Announcement() {

  return (
    <>
      <Collapse
        title={'가입 시 유의사항'}
        descriptions={[
          '앨리 ‘리스하기’ 서비스는 일정 기간 동안 정해진 금액을 지불하고 납부가 종료되면 기기를 소유할 수 있는 서비스에요.',
          '리스 기간 동안 기기를 안전하게 이용하실 수 있도록 전문가 수리 서비스. 대인/대물 보험 적용, 전문 상담 서비스를 지원해 드려요.',
          '리스 신청이 완료된 후 앨리 팀에서 유선으로 계약 진행을 안내해드려요. 계약이 완료되면 기기를 수령할 수 있어요.',
          '리스한 기기는 지정된 캠프에 방문하여 직접 수령하거나 배송을 요청할 수 있어요. 배송 요청 시 영업일 기준 최대 7일까지 소요될 수 있어요.',
          '리스 시작 72시간 이내 기기 결함이 확인 되면, 앨리 캠프에서 고장 점검 후 기기를 교체 하실 수 있어요. (단, 이용 중에 발생한 우발적 손상이 확인되면 수리 대금이 발생할 수 있어요.)',
          '리스 월 요금이 납부되지 않으면 키가 자동으로 잠기며, 미납 요금을 성공적으로 결제하면 다시 사용할 수 있어요.',
        ]}/>
      <Collapse
        title={'리스 해지 안내'}
        descriptions={[
          '리스 해지는 앨리 고객센터(1661-6025)을 통해 요청할 수 있어요.',
          '리스 해지 시 남은 리스 요금의 30%가 취소 수수료로 발생해요.',
          '반납된 기기는 앨리 캠프에서 검수를 거쳐요. 검수 중 우발적 손상이나 파손이 확인되면 수리 대금이 발생할 수 있어요.',
          '리스 해지 시 정해진 캠프에 직접 반납하거나 수거를 요청할 수 있고, 수거 요청 시 영업일 기준 최대 3일이 소요될 수 있어요.',
        ]}/>
    </>
  )
}

export default Announcement