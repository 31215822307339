import styled from "styled-components";

const Wrapper = styled.div`
  height: 50px;
  padding: 12px 20px;
  display: flex;
  justify-content: center;
`
const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  margin: 0 5px;
  border-radius: 12px;
  text-align: center;
  color: white;
  background-color: ${props => props.theme.blackColor};
  font-size: 12px;
  font-weight: 500;
`

function DeviceTag({ leaseModelTag = [] }) {

  return (
    <Wrapper>
      {leaseModelTag.map(tag => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </Wrapper>
  )
}

export default DeviceTag