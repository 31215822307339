import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components';
import theme from "./contexts/theme";

import { GlobalContextProvider } from "./contexts/store";
import Router from "./routes";

const queryClient = new QueryClient({
                                      defaultOptions: {
                                        queries: {
                                          refetchOnWindowFocus: false,
                                          retry: 0,
                                        },
                                      }
                                    });

const Container = styled.div`
  padding-bottom: 150px;
  font-family: SUIT, Helvetica, serif;
`
function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalContextProvider>
          <ThemeProvider theme={theme}>
            <Container>
              <Router/>
            </Container>
          </ThemeProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;