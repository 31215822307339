import React from "react";
import styled from "styled-components";

const SubTitleWrapper = styled.div`
  height: 50px;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    flex: 1;
    display: flex;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`
const Activated = styled.div`
  font-weight: 700;
  font-size: ${props => props.isTitle ? '22px' : '18px'};
`
const InActivated = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.greyColor1};
`
const SubTitle = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${props => props.theme.greyTextColor};
`

function TabBar({
  left: { text: leftText, onClick: leftOnClick } = {},
  center: { text: centerText, onClick: centerOnClick, isTitle = false } = {},
  right: { text: rightText, onClick: rightOnClick } = {},
  subTitle
}) {

  return (
    <>
      <SubTitleWrapper>
        <InActivated onClick={leftOnClick}>{leftText}</InActivated>
        <Activated isTitle={isTitle} onClick={centerOnClick}>{centerText}</Activated>
        <InActivated onClick={rightOnClick}>{rightText}</InActivated>
      </SubTitleWrapper>

      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </>
  )
}

export default TabBar