import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import GlobalContext from "../../../contexts/store";
import { nativeInvoke } from "../../../utils/bridge";
import { InfoCircleIcon } from "../../../images";
import Loading from "../../../components/common/Loading";
import FixedBottomButtonWrapper from "../common/FixedBottomButtonWrapper";

import { postLeaseOrderAPI } from "./order.service";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 14px;
  color: ${props => props.theme.greyTextColor};
  img {
    margin-right: 4px;
  }
`
const OrderButton = styled.button`
  padding: 18px 20px;
  background-color: ${props => props.theme.blackColor};
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`

function FixedLeaseOrderBottomButton({ isAllCheckedRequired }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { state: { selectedOptions } } = useContext(GlobalContext)

  const { mutate: leaseMutation } = useMutation(postLeaseOrderAPI, {
    onSuccess: () => {
      navigate('/leaseSuccess')
      setLoading(false)
    },
    onError: (err) => {
      console.log('리스주문 실패', err.response.data.message, err)
      nativeInvoke('leaseFailed', { message: err.response.data.message })
      setLoading(false)
    }
  })

  function handleLeaseOrder() {
    if (loading) return
    setLoading(true)
    if (!isAllCheckedRequired()) {
      nativeInvoke('checkServiceTerms')
      return setLoading(false)
    }

    console.log('selectedOptions',selectedOptions)
    leaseMutation(selectedOptions)
  }

  return (
    <>
      {loading && <Loading/>}

      <FixedBottomButtonWrapper>
        <Wrapper>
          <InfoText>
            <img src={InfoCircleIcon}/>
            <span>접수 후 계약 진행을 위해 유선 안내를 드립니다.</span>
          </InfoText>

          <OrderButton onClick={handleLeaseOrder}>이 견적으로 접수하기</OrderButton>
        </Wrapper>
      </FixedBottomButtonWrapper>
    </>
  )
}

export default FixedLeaseOrderBottomButton