import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #EEEDF0;
  background-color: white;
`

function FixedBottomButtonWrapper({children}) {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default FixedBottomButtonWrapper