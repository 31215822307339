import React from "react";
import styled from "styled-components";
import Lottie from 'lottie-react'
import { LoadingLottie } from "../../images";

const Dim = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`
const LottieWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform:translate(-50%, -50%);
`

function Loading() {

  return (
    <>
      <Dim/>
      <LottieWrapper>
        <Lottie style={{width:'150px'}} animationData={LoadingLottie}/>
      </LottieWrapper>
    </>
  )
}

export default Loading