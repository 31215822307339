import styled from "styled-components";

const Wrapper = styled.div`
`
const Title = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  padding: 16px 28px;
  font-size: 15px;
  font-weight: 400;
`
const SpecsWrapper = styled.div`
  padding: 12px 28px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`
const SpecColumn = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  margin-bottom: 8px;
  span:first-child {
    width: 80px;
    font-weight: 500;
    margin-right: 12px;
  }
  span:last-child {
    flex: 1;
    font-weight: 400;
    color: ${props => props.theme.greyTextColor};
  }
`

function DetailSpecs({ modelSpec }) {

  return (
    <Wrapper>
      <Title>기기 상세 스펙</Title>
      <SpecsWrapper>
        {modelSpec && Object.values(modelSpec).map(ele => (
          <SpecColumn key={ele[0]}>
            <span>{ele[0]}</span>
            <span>{ele[1]}</span>
          </SpecColumn>
        ))}
      </SpecsWrapper>
    </Wrapper>
  )
}

export default DetailSpecs