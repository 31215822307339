import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css/pagination";

const Wrapper = styled.div`
  position: relative;
  padding: 8px;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 16px 20px;
  line-height: 24px;
`
const SwiperWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 10px;
`
const ProductImage = styled.img`
  width: 200px;
  height: 200px;
`
const ProductName = styled.div`
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`

function PreviewDeviceCarousel({ modelName, imageUrls }) {

  return (
    <Wrapper>
      <Title>리스 상품 구성</Title>

      <SwiperWrapper>
        <Swiper
          style={{ height: '280px' }}
          loopFillGroupWithBlank={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {imageUrls.map(image => (
            <SwiperSlide key={image.imageUrl}
                         style={{ backgroundColor: '#F8F7FA', height: '200px', opacity: 1, borderRadius: '4px' }}
            >
              <ProductImage src={image.imageUrl}/>
              <ProductName>{image.title}</ProductName>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>

    </Wrapper>
  )
}

export default PreviewDeviceCarousel