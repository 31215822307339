import styled from 'styled-components'
import { QuestionMarkIcon } from "../../../../images";
import { nativeInvoke } from "../../../../utils/bridge";

const Wrapper = styled.div`
  padding: 8px 20px;
`
const InfoTextContainer = styled.div`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.dividerColor};
  border-radius: 20px;
  font-size: 12px;
  color: ${props => props.theme.greyTextColor};
`
const QuestionButton = styled.img`
  margin-left: 5.5px;
`

function DepositRefundInfo({ deposit }) {

  return (
    <Wrapper>
      <InfoTextContainer>
        <div>{deposit ?? '리스 보증금 : 0원'}</div>
        <QuestionButton src={QuestionMarkIcon}
                        onClick={() => {
                          nativeInvoke(
                            'showPopup',
                            { content: '리스 보증금은 리스를 종료하신 후 정상 반납이 확인되면 전액 환급되는 금액이에요.' }
                          )
                        }}/>
      </InfoTextContainer>
    </Wrapper>
  )
}

export default DepositRefundInfo