import React, { useContext } from "react";
import CountUp from "react-countup";
import styled from "styled-components";

import { DefaultDescriptionType, OptionType, OptionValueType } from "../type";
import GlobalContext from "../../../contexts/store";

const Wrapper = styled.div`
  padding: 12px 20px;
`
const MonthlyPriceWrapper = styled.div`
  padding: 0 8px;
  margin-bottom: 20px;
`
const MainColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 11px;
`
const MainColumnText = styled.div`
`
const MainColumnPrice = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0 4px;
    font-size: 22px;
    font-weight: 700;
  }
`
const SubColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.greyColor1};
`

const DescriptionWrapper = styled.div`
  padding: 20px;
  border: 1px solid #EEEDF0;
  border-radius: 12px;
`
const DescriptionItem = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`
const SelectedOption = styled.div`
  width: 80px;
  color: ${props => props.theme.greyColor1};
`
const SelectedOptionValue = styled.div`
  flex: 1;
`

function SelectionDescriptions({ calcPrices }) {
  const { state: { selectedOptions } } = useContext(GlobalContext)

  function getOptionValue(value, option) {
    console.log('옵션선택', value, option)

    if (!value || (value.length === 0)) return DefaultDescriptionType[option]
    if (typeof value === 'number') return value + '개월'
    if (Array.isArray(value)) {
      const values = value?.map(ele => OptionValueType[option][ele])
      return values.join(', ')
    }
    return OptionValueType[option][value]
  }

  return (
    <Wrapper>
      <MonthlyPriceWrapper>
        <MainColumn>
          <MainColumnText>월 예상 금액</MainColumnText>
          <MainColumnPrice>
            매월 <span>
            <CountUp start={100000} end={calcPrices?.totalPrice ?? 200000} separator={','} duration={0.4}/>
          </span> 원
          </MainColumnPrice>
        </MainColumn>

        {(selectedOptions.extraProductType.includes('LEASE_BATTERY')
          || selectedOptions.extraProductType.includes('LEASE_CHARGER')) && (
          <>
            <SubColumn>
              <div>+ 추가 옵션 금액(첫 1회만 결제)</div>
              <div><CountUp start={100000} end={calcPrices?.extraPrice ?? 200000} separator={','} duration={0.4}/> 원
              </div>
            </SubColumn>
          </>
        )}
      </MonthlyPriceWrapper>

      <DescriptionWrapper>
        {Object.keys(selectedOptions).map(option => (
          <DescriptionItem key={option}>
            <SelectedOption>{OptionType[option]}</SelectedOption>
            <SelectedOptionValue>
              {option === 'modelType'
                ? selectedOptions[option]
                : getOptionValue(selectedOptions[option], option)}
            </SelectedOptionValue>
          </DescriptionItem>
        ))}
      </DescriptionWrapper>
    </Wrapper>
  )
}

export default SelectionDescriptions