import styled from "styled-components";
import { nativeInvoke } from "../../../utils/bridge";
import FixedBottomButtonWrapper from "../common/FixedBottomButtonWrapper";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Button = styled.button`
  padding: 18px 20px;
  background-color: ${props => props.isDark ? props.theme.blackColor : props.theme.dividerColor};
  border: none;
  border-radius: 30px;
  color: ${props => props.isDark ? 'white' : 'black'};
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`

function FixedLeaseSuccessBottomButton() {

  function onMoveToApp() {
    nativeInvoke('leaseSuccess')
  }

  function onCustomerService() {
    nativeInvoke('moveServiceCenter')
  }

  return (
    <FixedBottomButtonWrapper>
      <Wrapper>
        <Button style={{ marginBottom: '12px' }} isDark={true} onClick={onMoveToApp}>메인으로</Button>
        <Button isDark={false} onClick={onCustomerService}>고객센터</Button>
      </Wrapper>
    </FixedBottomButtonWrapper>
  )
}

export default FixedLeaseSuccessBottomButton