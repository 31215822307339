import styled from "styled-components";
import DaumPostcode from 'react-daum-postcode';

import { nativeInvoke } from "../utils/bridge";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
`

function PostCode() {
  const handleComplete = data => {

    const address = {
      roadAddress: data.roadAddress,
      jibunAddress: data.jibunAddress === '' ? data.autoJibunAddress : data.jibunAddress,
      zonecode: data.zonecode,
      userSelectedType: data.userSelectedType,
      sido: data.sido,
      buildingName: data.buildingName
    }

    return nativeInvoke('findAddress', address)
  }

  return (
    <Container>
      <DaumPostcode
        onComplete={handleComplete}
        autoClose={false}
        style={{ height: '100%' }}
      />
    </Container>
  )
}

export default PostCode