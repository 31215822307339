export function nativeInvoke(action, body = {}) {
  try {
    window.Bridge[action](JSON.stringify(body));
    return;
  } catch {
  }

  try {
    window.webkit.messageHandlers.Bridge.postMessage({ action, ...body });
    return;
  } catch {
  }
  console.log("Native Invoke", action, body);
}