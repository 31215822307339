import styled from "styled-components";

const Wrapper = styled.div`
  padding: 16px 20px;
  text-align: center;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 26px;
`
const LeasePrice = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.greyTextColor};
`

function DeviceTitle({ title, leasePrice }) {

  return (
    <Wrapper>
      <Title>{title}</Title>
      <LeasePrice>{leasePrice}</LeasePrice>
    </Wrapper>
  )
}

export default DeviceTitle