import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import CountUp from 'react-countup';

import FixedBottomButtonWrapper from "./FixedBottomButtonWrapper";
import { useCalculatePrice } from "./hook";
import GlobalContext from "../../../../contexts/store";

const Wrapper = styled.div`
  width: 100%;
`

const PriceDescriptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`
const PriceDescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isRight ? 'flex-end' : 'flex-start'};
`
const DescriptionTitle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 16px;
  font-weight: 700;
`
const DescriptionSubTitle = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.greyTextColor};
  margin-bottom: 2px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  padding: 18px 20px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`
const TotalPriceColumn = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 2px;
  color: black;

  span {
    margin-left: 8px;
  }
`
const LeftButton = styled(Button)`
  background-color: ${props => props.theme.dividerColor};
  color: black;
  margin-right: 6px;
`
const RightButton = styled(Button)`
  background-color: ${props => props.theme.blackColor};
  color: white;
  margin-left: 6px;
`

function FixedCalculatePriceBottomButton({ now, prev, next, move, optionType }) {
  const { data: { calcPrices } } = useCalculatePrice(optionType);
  const { state: { selectedDevice, selectedOptions, selectedOptionsLabel }, setState } = useContext(GlobalContext)

  useEffect(() => {
    const payload = {
      ...selectedOptions,
      ...selectedOptionsLabel,
      modelName: selectedDevice?.modelName,
      depositDisplayText: selectedDevice?.deposit ?? '리스 보증금 : 0원',
      deposit: calcPrices?.deposit,
      deliveryPrice: calcPrices?.deliveryPrice,
      displayInsuranceFee: calcPrices?.displayInsuranceFee,
      leasePrice: calcPrices?.totalPrice,
      productId: (selectedOptions.productId === -1 || !selectedOptions.productId)
        ? null
        : Number(selectedOptions.productId)
    }
    setState(prev => ({ ...prev, orderPayload: payload }))

  }, [calcPrices, selectedOptions, selectedOptionsLabel])

  return (
    <FixedBottomButtonWrapper>
      <Wrapper>
        <PriceDescriptionsWrapper>
          <PriceDescriptionColumn>
            <DescriptionSubTitle>
              {calcPrices?.period}개월 간
            </DescriptionSubTitle>
            <DescriptionTitle>
              리스 예상요금
            </DescriptionTitle>
          </PriceDescriptionColumn>

          <PriceDescriptionColumn isRight={true}>
            <DescriptionSubTitle>
              {calcPrices?.displayInsuranceFee ?? '보험료 0원 포함'}
            </DescriptionSubTitle>
            <DescriptionTitle>
              <TotalPriceColumn>월 <span>
                <CountUp start={50000} end={calcPrices?.totalPrice ?? 100000} separator={','} duration={0.7}
                         style={{ margin: 0 }}/>
              </span>원</TotalPriceColumn>
            </DescriptionTitle>
          </PriceDescriptionColumn>
        </PriceDescriptionsWrapper>

        <ButtonWrapper>
          <LeftButton onClick={() => move(prev.value)}>이전</LeftButton>
          <RightButton onClick={() => move(next.value)}>다음</RightButton>
        </ButtonWrapper>
      </Wrapper>
    </FixedBottomButtonWrapper>
  )
}

export default FixedCalculatePriceBottomButton