import React, { useContext } from "react";
import { useQuery } from "react-query";

import GlobalContext from "../../contexts/store";
import Loading from "../../components/common/Loading";
import { nativeInvoke } from "../../utils/bridge";
import TopBar from "../../domain/v1/common/TopBar";
import TabBar from "../../domain/v1/common/TabBar";
import Progress from "../../domain/v1/common/Progress";
import SelectDeviceCarousel from "../../domain/v1/device/SelectDeviceCarousel";
import DeviceTitle from "../../domain/v1/device/DeviceTitle";
import DeviceTag from "../../domain/v1/device/DeviceTag";
import Divider from "../../domain/v1/common/Divider";
import DetailSpecs from "../../domain/v1/device/DetailSpecs";
import Announcement from "../../domain/v1/device/Announcement";
import DeviceFixedBottomButton from "../../domain/v1/device/DeviceFixedBottomButton";
import { getDevicesAPI } from "../../domain/v1/device/device.service";
import { allSteps } from "../../domain/v1/common/hook";

function SelectDevicePage() {
  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { devices }, isLoading } = useDeviceQuery(selectedDevice, setState)

  const {
    modelName, leasePrice, modelSpec, leaseModelTag, modelType, steps
  } = selectedDevice ?? devices[0]

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar rightItem={{}} backClick={() => nativeInvoke('back')}/>
      <TabBar center={{ text: '어떤 기기를 리스하시겠어요?' }}/>
      <Progress/>

      <SelectDeviceCarousel devices={devices}/>

      <DeviceTitle title={modelName} leasePrice={leasePrice}/>
      <DeviceTag leaseModelTag={leaseModelTag}/>
      <Divider/>
      <DetailSpecs modelSpec={modelSpec}/>
      <Divider/>
      <Announcement/>

      <DeviceFixedBottomButton modelType={modelType} steps={steps}/>
    </>
  )
}

export default SelectDevicePage

function useDeviceQuery() {

  const {
    data: deviceData,
    isLoading
  } = useQuery(['device'], getDevicesAPI, {
    suspense: true,
  })

  const devices = deviceData?.map(device => ({
    ...device,
    steps: allSteps.find(ele => ele.modelType === device.modelType).steps
  }))

  return {
    data: {
      devices,
    },
    isLoading,
  }
}