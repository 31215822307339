import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import GlobalContext from "../../../contexts/store";
import { useStepControl } from "../../../domain/v2/lease/common/hook";

import TabBar from "../../../domain/v2/lease/common/TabBar";
import TopBar from "../../../domain/v2/lease/common/TopBar";
import Progress from "../../../domain/v2/lease/common/Progress";
import DisplayProductPicture from "../../../domain/v2/lease/common/DisplayProductPicture";
import SelectProductOptionButton from "../../../domain/v2/lease/common/SelectProductOptionButton";
import SelectProductOptionMenu from "../../../domain/v2/lease/common/SelectProductOptionMenu";
import FixedCalculatePriceBottomButton from "../../../domain/v2/lease/common/FixedCalculatePriceBottomButton";

import { getInsuranceAPI } from "../../../domain/v2/lease/insurance/insurance.service";
import Loading from "../../../components/common/Loading";
import { nativeInvoke } from "../../../utils/bridge";

function SelectInsurancePage() {
  const { modelType } = useParams()
  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { insurances }, isLoading } = usePeriodQuery(modelType)

  const { prev, now, next, move } = useStepControl('insurance')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={insurances?.imageUrl} style={{ width: '120px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={insurances?.leaseInsurances[0].insuranceType.toString()}
        column={insurances?.leaseInsurances.length === 1 ? 1 : 2}
        menuTitle={{
          title: '필수 보험 선택', subTitle: '보험 상세',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://teamalley.notion.site/d6661e77499742bcba472518b13b322c' }
          )
        }}
        onChange={(value) => {
          const target = insurances?.leaseInsurances.find(ele => ele.insuranceType === value)
          setState(prev => ({
            ...prev,
            selectedOptions: { ...prev.selectedOptions, insuranceType: value },
            selectedOptionsLabel: { ...prev.selectedOptionsLabel, insuranceName: target?.displayInsurance }
          }))
        }}
      >
        {insurances?.leaseInsurances?.map(ele => (
          <SelectProductOptionButton
            key={ele.insuranceType}
            value={ele.insuranceType}
            title={ele.displayInsurance}
            subTitle={ele.displayDescription}
          />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'insuranceType'} now={now} prev={prev} next={next} move={move}/>
    </>
  )
}

export default SelectInsurancePage

function usePeriodQuery(modelType) {

  const {
    data: insurances, isLoading
  } = useQuery(['insurance', modelType], () => getInsuranceAPI(modelType), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      insurances
    },
    isLoading
  }
}