import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { nativeInvoke } from "../../../../utils/bridge";
import GlobalContext from "../../../../contexts/store";
import { getAccessToken } from "../../../../utils/api";
import { ArrowLongLeftIcon } from "../../../../images";

import FixedBottomButtonWrapper from "../common/FixedBottomButtonWrapper";

const LeftButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #F8F7FA;
  border: none;
  margin-right: 12px;
`
const RightButton = styled.button`
  padding: 18px 20px;
  background-color: ${props => props.theme.blackColor};
  border: none;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  flex: 1;
`

function DeviceFixedBottomButton({ modelType, steps }) {
  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const navigate = useNavigate()

  async function handleNextStep() {
    if (!getAccessToken()) {
      return nativeInvoke('goToLogin')
    }
    // moveNext()
    /** 유저 검증 모달 호출 */
    nativeInvoke('showRegisterModal', { age: modelType === 'S9' ? 16 : 21 })
  }

  useEffect(() => {
    window.injectMoveNext = moveNext
  }, [modelType])

  function moveNext() {
    setState(prev => ({ ...prev, selecting: true }))
    navigate(`${selectedDevice.modelType}/${selectedDevice.steps[1]}`)
    navigate(`${modelType}/${steps[1]}`)
  }

  return (
    <FixedBottomButtonWrapper border={false}>
      <LeftButton onClick={() => nativeInvoke('goToMain')}>
        <img src={ArrowLongLeftIcon}/>
      </LeftButton>

      <RightButton onClick={handleNextStep}>리스 옵션 선택하기</RightButton>
    </FixedBottomButtonWrapper>
  )
}

export default DeviceFixedBottomButton