import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import GlobalContext from "../../../contexts/store";
import { useStepControl } from "../../../domain/v2/lease/common/hook";

import TabBar from "../../../domain/v2/lease/common/TabBar";
import TopBar from "../../../domain/v2/lease/common/TopBar";
import Progress from "../../../domain/v2/lease/common/Progress";
import DisplayProductPicture from "../../../domain/v2/lease/common/DisplayProductPicture";
import SelectProductOptionButton from "../../../domain/v2/lease/common/SelectProductOptionButton";
import SelectProductOptionMenu from "../../../domain/v2/lease/common/SelectProductOptionMenu";
import FixedCalculatePriceBottomButton from "../../../domain/v2/lease/common/FixedCalculatePriceBottomButton";

import Loading from "../../../components/common/Loading";
import { nativeInvoke } from "../../../utils/bridge";
import { getExtraOptionAPI } from "../../../domain/v2/lease/extraOption/extraOption.service";
import { hasMaxMin } from "../../../utils/organizeData";

function SelectExtraOptionPage() {
  const { modelType } = useParams()
  const { state: { selectedDevice, selectedOptions: { periodType } }, setState } = useContext(GlobalContext)
  const { data: { extraOption }, isLoading } = useExtraOptionQuery(modelType, periodType)

  const { prev, now, next, move } = useStepControl('extraOption')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={extraOption?.imageUrls} style={{ width: '200px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={hasMaxMin(extraOption?.leaseOptions, 'price')?.productId.toString()}
        menuTitle={{
          title: '기기 옵션 선택',
          // subTitle: '옵션 상세',
          subTitle: '',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://www.notion.so/teamalley/a4fbb8e0b2084ba58296a4a490c91c94' }
          )
        }}
        onChange={(value) => {
          const target = extraOption?.leaseOptions?.find(ele => String(ele.productId) === value)
          setState(prev => ({
            ...prev,
            selectedOptions: { ...prev.selectedOptions, productId: Number(value) },
            selectedOptionsLabel: {
              ...prev.selectedOptionsLabel,
              extraProductName: target?.productName ?? '추가 안함'
            }
          }))
        }}
      >
        {extraOption?.leaseOptions?.map(ele => (
          <SelectProductOptionButton
            key={ele.productId}
            value={ele.productId.toString()}
            title={ele.productName}
            subTitle={ele.displayPrice}
          />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'productId'} now={now} prev={prev} next={next} move={move}/>
    </>
  )
}

export default SelectExtraOptionPage

function useExtraOptionQuery(modelType, periodType) {

  const {
    data: extraOption, isLoading
  } = useQuery(['extraOption', modelType, periodType], () => getExtraOptionAPI(modelType, periodType), {
    refetchOnWindowFocus: false,
    enabled: !!periodType
  })

  return {
    data: {
      extraOption: extraOption ? {
        imageUrls: extraOption?.imageUrls,
        leaseOptions: [
          {
            description: "추가 금액 없음",
            displayPrice: "추가 금액 없음",
            extraProductType: "NONE",
            price: 0,
            productId: -1,
            productName: "추가 안함"
          },
          ...extraOption?.leaseOptions
        ]
      } : []
    },
    isLoading
  }
}