import { Outlet } from 'react-router-dom'
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 56px;
`

function LeaseContainer() {

  return (
    <Wrapper>
      <Outlet/>
    </Wrapper>
  )
}

export default LeaseContainer