import axios from 'axios'

export function getStageHost() {
  // return "https://stage.api.alley.swingmobility.dev"; // stage
  if (window?.location.href.indexOf("localhost") !== -1) return "http://localhost:8080";
  if (window?.location.href.indexOf("192.168") !== -1) return "https://stage.api.alley.swingmobility.dev";
  if (window?.location.href.indexOf("stage") !== -1) return "https://stage.api.alley.swingmobility.dev";
  return "https://api.alley.swingmobility.dev"; // product
}

/** get Token From App */
export const getAccessToken = () => {

  return window.localStorage.getItem('accessToken')
}

export const API = axios.create({
                                  baseURL: getStageHost(),
                                  headers: {
                                    Authorization: `Bearer ${getAccessToken()}`,
                                  },
                                })