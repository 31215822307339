import styled from "styled-components";

const Wrapper = styled.div`
`
const DividerBar = styled.div`
  background-color: ${props => props.theme.dividerColor};
  height: 12px;
`

function Divider() {


  return (
    <Wrapper>
      <DividerBar />
    </Wrapper>
  )
}

export default Divider