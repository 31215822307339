import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import GlobalContext from "../../contexts/store";
import { useStepControl } from "../../domain/v1/common/hook";
import Loading from "../../components/common/Loading";
import TopBar from "../../domain/v1/common/TopBar";
import TabBar from "../../domain/v1/common/TabBar";
import Progress from "../../domain/v1/common/Progress";
import DisplayProductPicture from "../../domain/v1/common/DisplayProductPicture";
import SelectProductOptionMenu from "../../domain/v1/common/SelectProductOptionMenu";
import SelectProductOptionButton from "../../domain/v1/common/SelectProductOptionButton";
import InfoMessage from "../../domain/v1/period/InfoMessage";
import FixedCalculatePriceBottomButton from "../../domain/v1/common/FixedCalculatePriceBottomButton";
import { getPeriodAPI } from "../../domain/v1/period/period.service";

function SelectPeriodPage() {
  const { modelType } = useParams()

  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { periods }, isLoading } = usePeriodQuery(modelType)
  const { prev, now, next, move } = useStepControl('period')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>


      <DisplayProductPicture>
        <img src={periods?.imageUrl} style={{ width: '120px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={periods?.leasePeriods?.sort((a, b) => a.period - b.period)[0].period.toString()}
        menuTitle={{ title: '리스 기간을 선택해주세요', subTitle: '', onClick: () => '' }}
        onChange={(value) => setState(prev => ({
          ...prev,
          selectedOptions: { ...prev.selectedOptions, periodType: Number(value) }
        }))}
        column={periods?.leasePeriods.length === 1 ? 1 : 2}
      >
        {periods?.leasePeriods
          ?.sort((a, b) => a.period - b.period)
          ?.map(ele => (
            <SelectProductOptionButton
              key={ele.period}
              value={ele.period.toString()}
              title={ele.displayPeriod}
            />))}
      </SelectProductOptionMenu>

      <InfoMessage text={'선택한 기간 동안 금액을 모두 납부하면, 기간 종료 후 기기를 소유할 수 있어요.'}/>

      <FixedCalculatePriceBottomButton optionType={'periodType'} now={now} next={next} move={move}/>
    </>
  )
}

export default SelectPeriodPage

function usePeriodQuery(modelType) {

  const {
    data: periods, isLoading
  } = useQuery(['period', modelType], () => getPeriodAPI(modelType), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      periods
    },
    isLoading
  }
}