import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import GlobalContext from "../../../contexts/store";
import { useStepControl } from "../../../domain/v2/lease/common/hook";

import DisplayProductPicture from "../../../domain/v2/lease/common/DisplayProductPicture";
import TopBar from "../../../domain/v2/lease/common/TopBar";
import Progress from "../../../domain/v2/lease/common/Progress";
import TabBar from "../../../domain/v2/lease/common/TabBar";
import SelectProductOptionMenu from "../../../domain/v2/lease/common/SelectProductOptionMenu";
import SelectProductOptionButton from "../../../domain/v2/lease/common/SelectProductOptionButton";
import FixedCalculatePriceBottomButton from "../../../domain/v2/lease/common/FixedCalculatePriceBottomButton";

import { getDeviceGradeAPI } from "../../../domain/v2/lease/grade/grade.service";
import Loading from "../../../components/common/Loading";
import { nativeInvoke } from "../../../utils/bridge";
import { hasMaxMin } from "../../../utils/organizeData";

function SelectGradePage() {
  const { modelType } = useParams()

  const { state: { selectedDevice }, setState } = useContext(GlobalContext)
  const { data: { deviceGrade }, isLoading } = useGradeQuery(modelType);
  const { prev, now, next, move } = useStepControl('grade')

  return (
    <>
      {isLoading && <Loading/>}

      <TopBar
        title={selectedDevice?.modelName}
        rightItem={{}}
        backClick={() => move(prev.value)}
      />
      <TabBar
        left={{ text: prev?.text, onClick: () => move(prev.value) }}
        center={{ text: now?.text }}
        right={{ text: next?.text, onClick: () => move(next.value) }}
      />
      <Progress/>

      <DisplayProductPicture>
        <img src={deviceGrade?.imageUrl} style={{ width: '180px' }}/>
      </DisplayProductPicture>

      <SelectProductOptionMenu
        initialValue={hasMaxMin(deviceGrade?.grades, 'lowestPrice')?.grade}
        menuTitle={{
          title: '기기 등급 선택', subTitle: '등급 기준',
          onClick: () => nativeInvoke(
            'moveLink',
            { url: 'https://www.notion.so/teamalley/f99e793a61d14a41afa1db2fd1afd93f' }
          )
        }}
        onChange={(value) => {
          const target = deviceGrade?.grades.find(ele => ele.grade === value)
          setState(prev => ({
            ...prev,
            selectedOptions: { ...prev.selectedOptions, grade: value },
            selectedOptionsLabel: { ...prev.selectedOptionsLabel, gradeName: target?.displayGrade }
          }))
        }}
        column={deviceGrade?.grades.length === 1 ? 1 : 2}
      >
        {deviceGrade?.grades?.map(ele => (
          <SelectProductOptionButton
            key={ele.grade}
            value={ele.grade}
            title={ele.displayGrade}
            subTitle={ele.subTitle}
          />))}
      </SelectProductOptionMenu>

      <FixedCalculatePriceBottomButton optionType={'grade'} now={now} prev={prev} next={next} move={move}/>
    </>
  )
}

export default SelectGradePage

function useGradeQuery(modelType) {

  const {
    data: deviceGrade, isLoading
  } = useQuery(['grade', modelType], () => getDeviceGradeAPI(modelType), {
    refetchOnWindowFocus: false,
  })

  return {
    data: {
      deviceGrade,
    },
    isLoading
  }
}