import { useContext, useEffect } from "react";
import GlobalContext from "./contexts/store";
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom'

import Loading from "./components/common/Loading";
import { ErrorBoundary } from "./components/common/ErrorBoundary";

import SelectDevicePage from "./pages/v2/lease/SelectDevicePage";
import SelectPeriodPage from "./pages/v2/lease/SelectPeriodPage";
import SelectInsurancePage from "./pages/v2/lease/SelectInsurancePage";
import SelectExtraOptionPage from "./pages/v2/lease/SelectExtraOptionPage";
import SelectGradePage from "./pages/v2/lease/SelectGradePage";
import LeaseContainer from "./pages/v2/lease/leaseContainer";

import * as V1SelectDevicePage from "./pages/v1/SelectDevicePage";
import * as V1SelectGradePage from "./pages/v1/SelectGradePage";
import * as V1SelectPeriodPage from "./pages/v1/SelectPeriodPage";
import * as V1SelectInsurancePage from "./pages/v1/SelectInsurancePage";
import SelectOptionPage from "./pages/v1/SelectOptionPage";
import OrderPage from "./pages/v1/OrderPage";
import SuccessPage from "./pages/v1/SuccessPage";
import PostCode from "./pages/PostCode";

function Router() {
  const location = useLocation()
  const navigate = useNavigate()
  const { state: { selecting } } = useContext(GlobalContext)

  useEffect(() => {
    if (!selecting) {
      console.log('go to home & refresh')
      if (location.pathname !== '/v2/lease' && location.pathname.includes('v2')) {
        navigate('/v2/lease')
      }
    }
  }, [location])

  return (
    <>
      <ErrorBoundary fallback={Error}>
        <Routes>
          <Route path={'/v2'}>
            <Route path={'lease'} element={<LeaseContainer/>}>
              <Route path={''} element={<SelectDevicePage/>}/>
              <Route path={':modelType/grade'} element={<SelectGradePage/>}/>
              <Route path={':modelType/period'} element={<SelectPeriodPage/>}/>
              <Route path={':modelType/insurance'} element={<SelectInsurancePage/>}/>
              <Route path={':modelType/extraOption'} element={<SelectExtraOptionPage/>}/>
            </Route>
          </Route>

          <Route path={'/'} element={<V1SelectDevicePage.default/>}/>
          <Route path={'/:modelType/grade'} element={<V1SelectGradePage.default/>}/>
          <Route path={'/:modelType/period'} element={<V1SelectPeriodPage.default/>}/>
          <Route path={'/:modelType/insurance'} element={<V1SelectInsurancePage.default/>}/>
          <Route path={'/:modelType/option'} element={<SelectOptionPage/>}/>
          <Route path={'/:modelType/order'} element={<OrderPage/>}/>
          <Route path={'/leaseSuccess'} element={<SuccessPage/>}/>

          <Route path={'/address'} element={<PostCode/>}/>
        </Routes>
      </ErrorBoundary>
    </>
  )
}

export default Router

function Error(error) {
  console.log(error)
  useEffect(() => {
    // window.location.reload()
  }, [])

  return <Loading/>
}