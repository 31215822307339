import { API } from "../../../utils/api";

export async function getDeviceGradeAPI(modelType) {
  return (await API.get(`lease/${modelType}/grades`)).data
}

export async function postCalculatePriceAPI(
  { modelType, grade, periodType, insuranceType, extraProductType }
) {
  return (await API.post(`lease/order/calc`, { modelType, grade, periodType, insuranceType, extraProductType })).data
}