import React from 'react';

export class ErrorBoundary extends React.Component{
  state;

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const FallbackUI = this.props.fallback
    if (this.state.hasError) return <FallbackUI error={this.state.error}/> || <h1>Error</h1>;

    return this.props.children;
  }
}