import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import GlobalContext, { leaseV2initialSelectedOptions } from "../../../../contexts/store";

function SelectDevice({ devices }) {
  const { state, setState } = useContext(GlobalContext)

  return (
    <Swiper
      style={{height:'200px'}}
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      loopFillGroupWithBlank={true}
      slidesPerView={3}
      initialSlide={devices.findIndex(device => device?.modelType === state.selectedDevice?.modelType)}
      spaceBetween={50}
      onRealIndexChange={(swiper) => setState(prev => ({
        ...prev,
        selectedDevice: devices[swiper.realIndex],
        selectedOptions: { ...leaseV2initialSelectedOptions, modelType: devices[swiper.realIndex].modelType }
      }))}
      coverflowEffect={{
        stretch: -40,
        // depth: -100,
        scale: 0.8,
        rotate: 0,
        slideShadows: false,
      }}
      loop={true}
      modules={[EffectCoverflow]}

      className="mySwiper"
    >
      {devices?.map((device, idx) => (
        <SwiperSlide key={idx}>
          <img src={device.mainImageUrl}/>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SelectDevice