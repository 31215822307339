import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  border-top: ${props => props.border ? '1px solid #EEEDF0' : 'none'};
  background-color: white;
  z-index: 500;
`

function FixedBottomButtonWrapper({children, border = true}) {

  return (
    <Wrapper border={border}>
      {children}
    </Wrapper>
  )
}

export default FixedBottomButtonWrapper