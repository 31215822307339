import { useQuery } from "react-query";
import { postCalculatePriceAPI } from "../grade/grade.service";
import { useContext } from "react";
import GlobalContext from "../../../../contexts/store";
import { useNavigate } from "react-router-dom";
import { nativeInvoke } from "../../../../utils/bridge";

export function useCalculatePrice(optionType) {
  const { state: { selectedOptions } } = useContext(GlobalContext)
  const {
    data: calcPrices
  } = useQuery(['calc', selectedOptions],
               () => postCalculatePriceAPI(
                 {
                   ...selectedOptions
                 }),
               {
                 refetchOnWindowFocus: false,
                 enabled: !!selectedOptions?.modelType && !!selectedOptions[optionType]
               })
  // console.log('calcPricescalcPricescalcPrices', calcPrices)
  return {
    data: {
      calcPrices
    }
  }
}

export const pageSteps = {
  device: '기기 선택',
  grade: '등급 선택',
  period: '기간 선택',
  insurance: '보험 선택',
  extraOption: '옵션 선택',
  order: '접수하기'
}

export const allSteps = [
  { modelType: 'S9', steps: ['device', 'grade', 'period', 'insurance', 'order'] },
  { modelType: 'PCX', steps: ['device', 'grade', 'period', 'insurance', 'extraOption', 'order'] },
  { modelType: 'NIU', steps: ['device', 'grade', 'period', 'insurance', 'extraOption', 'order'] },
]

export function useStepControl(now) {
  const navigate = useNavigate()
  const { state: { selectedDevice, orderPayload } } = useContext(GlobalContext)

  const nowIndex = selectedDevice?.steps.indexOf(now);
  const prev = selectedDevice?.steps[nowIndex - 1] && selectedDevice?.steps[nowIndex - 1]
  const next = selectedDevice?.steps[nowIndex + 1] && selectedDevice?.steps[nowIndex + 1]

  function move(destination) {
    if (destination === 'device') return navigate('/v2/lease')
    if (destination === 'order') {
      console.log('주문 정보전달 브릿지 실행 ', orderPayload)
      return nativeInvoke('orderPayload', orderPayload)
    }

    navigate(`/v2/lease/${selectedDevice.modelType}/${destination}`)
  }

  return {
    prev: { value: prev, text: pageSteps[prev] },
    next: next && { value: next, text: pageSteps[next] },
    now: { value: now, text: pageSteps[now] },
    move
  }
}

