import React from "react";
import styled from "styled-components";

const SubTitleWrapper = styled.div`
  height: 50px;
  padding: 12px 32px;
  display: flex;
  justify-content: space-between;
  div:first-child {
    flex: 1;
    display: flex;
  }
  div:nth-child(1) {
    flex: 1;
  }
  div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`
const Activated = styled.div`
  font-weight: 700;
  font-size: 18px;
`
const InActivated = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.greyColor1};
`

function TabBar({
  left: { text: leftText, onClick: leftOnClick } = {},
  center: { text: centerText, onClick: centerOnClick } = {},
  right: { text: rightText, onClick: rightOnClick } = {},
}) {

  return (
    <SubTitleWrapper>
      <InActivated onClick={leftOnClick}>{leftText}</InActivated>
      <Activated onClick={centerOnClick}>{centerText}</Activated>
      <InActivated onClick={rightOnClick}>{rightText}</InActivated>
    </SubTitleWrapper>
  )
}

export default TabBar