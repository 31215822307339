import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 12px 0;

`
const MenuTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`
const MenuTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`
const MenuSubTitle = styled.div`
  width: 75px;
  height: 24px;
  background-color: #F6F7F9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  color: ${props => props.theme.greyTextColor2};

  img {
    width: 12px;
  }
`
const MenuList = styled.div`
  padding: 8px 20px;
  display: grid;
  grid-template-columns: ${props => `repeat(${props.column}, 1fr)`};
  gap: 8px;

  #item1 {
    grid-column: ${props => props.isPlaceholder ? 1 : 'inherit'};
  }
`

function SelectProductOptionMenu({
  menuTitle: { title, subTitle, onClick: subTitleOnClick } = {},
  onChange,
  initialValue,
  isPlaceholder,
  column = 2,
  children
}) {
  const [selectedValue, setSelectedValue] = useState(initialValue && initialValue)

  useEffect(() => {
    if (!Array.isArray(initialValue)) {
      setSelectedValue(initialValue)
    }
  }, [initialValue])

  useEffect(() => {
    onChange(selectedValue)
  }, [selectedValue])

  return (
    <Wrapper>
      <MenuTitleWrapper>
        <MenuTitle>{title}</MenuTitle>
        {subTitle && <MenuSubTitle onClick={subTitleOnClick}>{subTitle} ></MenuSubTitle>}
      </MenuTitleWrapper>

      <MenuList column={column} isPlaceholder={isPlaceholder}>
        {children && React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { selectedValue, setSelectedValue, index })
        })}
      </MenuList>
    </Wrapper>
  )
}

export default SelectProductOptionMenu