import React from 'react'
import styled from "styled-components";
import Lottie from 'lottie-react'
import { SuccessLottie } from "../../images";
import FixedLeaseSuccessBottomButton from "../../domain/v1/success/FixedLeaseSuccessBottomButton";

const TitleWrapper = styled.div`
  margin-top: 56px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const MainTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
`
const SubTitle = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.greyTextColor};
`

const LottieWrapper = styled.div`
  margin: 35px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
`

function SuccessPage(){

  return (
    <>
      <TitleWrapper>
        <MainTitle>리스 접수가 완료되었어요</MainTitle>
        <SubTitle>{`
          고객센터에서 회원님의 계약 진행을 위해
          유선 안내를 드릴 예정이에요
          
          (앨리 고객센터 1661-6025)
        `}</SubTitle>
      </TitleWrapper>

      <LottieWrapper>
        <Lottie animationData={SuccessLottie}/>
      </LottieWrapper>

      <FixedLeaseSuccessBottomButton />
    </>
  )
}

export default SuccessPage