export const OptionType = {
  modelType: '기기',
  grade: '등급',
  periodType: '리스 기간',
  insuranceType: '필수 보험',
  extraProductType: '추가 옵션',
}

export const DefaultDescriptionType = {
  grade: '새 상품',
  periodType: '12개월',
  insuranceType: '없음',
  extraProductType: '없음',
}

export const OptionValueType = {
  grade: {
    NEW: '새 상품',
    A: '중고(A등급)',
    B: '중고(B등급)',
    C: '중고(C등급)',
  },

  extraProductType: {
    LEASE_BATTERY: '배터리',
    LEASE_CHARGER: '충전기',
  },

  //Period

  insuranceType: {
    BASIC: '책임보험',
    PREMIUM: '스윙 케어',
  },
}